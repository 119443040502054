<script setup>
import ArgonButton from "@/components/ArgonButton.vue";
import {computed, ref} from "vue";
import axios from "axios";
import {useStore} from "vuex";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import swal from "sweetalert2";

const store = useStore();
const loading = ref(false);


const taxDetails = ref({
  amount: 0,
  prn: "",
  transactionId: 0
});

const payTax = async () => {
  let data = {
    amount: taxDetails.value.amount,
    prn: taxDetails.value.prn,
    transactionId: taxDetails.value.transactionId,
  };
  const user = localStorage.getItem("user");
  try {
    const baseurl = process.env.VUE_APP_API_TAX_URL;
    console.log("tax url", baseurl);
    let url = `${baseurl}/payment`;
    const response = await axios.post(url, data, {
      headers: {
        "api-key": user.auth,
      },
    });
    if (response.status === 200) {
      console.log("response", response.data);
      swal.fire(`${response.data.data}`, 'Success!', 'success');
    } else {
      swal.fire(`${response.data.data}`, 'error!', 'error ');
    }
  } catch (error) {
    console.log(error);
    swal.fire(`${error.message}`, 'error!', 'error ');
  } finally {
    document.getElementById('closeTaxModal').click();
  }
};

const taxTypes = [
  { value: "IT_WHT_0222", label: "IT WHT 0222" },
  { value: "EXCISE", label: "EXCISE" }
];

const prn = ref({
  amount: "",
  tax_type: "",
});

const sendPRN = async () => {
  const baseurl = process.env.VUE_APP_API_TAX_URL;

  const selectedStartDate = pickerDate.value ? pickerDate.value[0] : null;
  const selectedEndDate = pickerDate.value ? pickerDate.value[1] : null;

  if (!selectedStartDate || !selectedEndDate) {
    swal.fire('Please select a start and end date', 'error!', 'error');
    return;
  }

  let data = {
    amount: String(prn.value.amount),
    tax_type: prn.value.tax_type,
    start_date: formatDate(selectedStartDate),
    end_date: formatDate(selectedEndDate),
  };

  const user = await store.state.user;
  let url = `${baseurl}/pin/pay`;

  try {
    loading.value = true;
    const response = await axios.post(url, data, {
      headers: {
        "api-key": user.auth,
      },
    });
    if (response.status === 200) {
      swal.fire('Sent successfully', 'Success!', 'success');
    } else {
      swal.fire(`${response.data.data}`, 'error!', 'error');
    }
  } catch (error) {
    console.log(error);
    swal.fire(`${error.message}`, 'error!', 'error');
  } finally {
    loading.value = false;
    pickerDate.value = null;
    document.getElementById('closePrnModal').click();
  }
};


const startDate = ref(new Date());
const endDate = computed(() => new Date(startDate.value));

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const pickerDate = ref([startDate.value, endDate.value]);

</script>

<template>
  <div class="py-4 container-fluid">
    <div class="my-4"  style="display: flex; flex-direction: row; justify-content: space-between">
      <div class="">
        <button type="button" class="btn btn-secondary me-2" data-bs-toggle="modal" data-bs-target="#payTaxModal">Tax Payment</button>
        <button type="button" class="btn btn-secondary ml-3" data-bs-toggle="modal" data-bs-target="#sendPRNModal">Send PRN</button>
      </div>
      <div class="" >
        <VueDatePicker
                      v-model="pickerDate"
                      range
                      multi-calendars
                      :enable-time-picker="false"
                      :clearable="false"
                      :input-class="'custom-datepicker'"
                  />
      </div>

    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="card-header pb-0 p-3">
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <h6 class="mb-0">Gaming/Bet Tax PRN</h6>
              </div>
              <div class="col-6 text-end">
                <argon-button color="dark" variant="gradient" class="mx-2">
                  <i class="fas fa-download me-2"></i>
                  Excel
                </argon-button>
                <argon-button color="dark" variant="gradient" class="mx-2">
                  <i class="fas fa-print me-2"></i>
                  Print
                </argon-button>
              </div>
            </div>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-12">
                Coming Soon
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="payTaxModal" tabindex="-1" role="dialog" aria-labelledby="payTaxModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Initiate Tax Payment</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-4">
                  <label>Amount</label>
                  <div class="form-group">
                    <input type="number" class="form-control" placeholder="Amount" v-model="taxDetails.amount" >
                  </div>
                </div>
                <div class="col-md-4">
                  <label>PRN</label>
                  <div class="form-group">
                    <input type="text" placeholder="Prn" class="form-control"  v-model="taxDetails.prn"  />
                  </div>
                </div>
                <div class="col-md-4">
                  <label>TransactionId</label>
                  <div class="form-group">
                    <input type="text" placeholder="TransactionId" class="form-control" v-model="taxDetails.transactionId"  />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn bg-gradient-secondary" data-bs-dismiss="modal" id="closeTaxModal">Close</button>
            <button type="button" class="btn bg-gradient-primary" @click="payTax">Submit</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade prnModal" id="sendPRNModal" tabindex="-1" role="dialog" aria-labelledby="modal-notification" aria-hidden="true">
      <div class="modal-dialog modal-danger modal-dialog-centered" role="document" style="width: 600px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Send PRN</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="prn Amount">Amount</label>
                    <input type="number" class="form-control" placeholder="Amount" v-model="prn.amount" >
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="tax type">Tax type</label>
                  <div class="ui required field">
                    <select class="ui search selection dropdown form-control" v-model="prn.tax_type">
                      <option v-for="type in taxTypes" :value="type.value" :key="type.value">
                        {{ type.label }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="">
                  <div class="form-group">
                    <label for="date picker">Choose Start and End Date</label>
                    <VueDatePicker v-model="pickerDate" range :enable-time-picker="false" class="datePicker" />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn bg-gradient-secondary" data-bs-dismiss="modal" id="closePrnModal">Close</button>
            <button type="button" class="btn bg-gradient-primary" @click="sendPRN" :disabled="loading">
              <span v-if="loading"><i class="fas fa-spinner fa-spin"></i> Loading...</span>
              <span v-else>Submit</span>
            </button>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<style>
.custom-datepicker {
  background-color: #8392ab !important;
}

</style>
