<script setup>
import { watch, onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import { ref } from "vue";
import axios from "axios";
import {useRouter} from "vue-router"
import Swal from "sweetalert2";


const body = document.getElementsByTagName("body")[0];

const store = useStore();
const router = useRouter();

let showEnterCode = ref(0)
let timeoutId = ref(null);


const startCodeTimeout = () => {
  if (timeoutId.value) {
    clearTimeout(timeoutId.value);
  }

  timeoutId.value = setTimeout(() => {
    showEnterCode.value = false;
    user.value.msisdn = "";
    user.value.password = "";
    user.value.code = "";

    Swal.fire({
      icon: "warning",
      title: "TimeOut",
      text: "OTP entry time has expired. Please log in again.",
      confirmButtonText: "OK",
    });
  }, 120000); // 2 minutes
};

const user = ref({
  msisdn: "",
  password: "",
  code: 0
})

const signIn = async () => {
  let data = {
    msisdn: parseInt(user.value.msisdn),
    password: user.value.password,
    code: user.value.code ? parseInt(user.value.code) : null,
  };

  const baseurl = process.env.VUE_APP_API_IDENTITY_URL;

  try {
    const response = await axios.post(baseurl, data);

    if (response.data.status === 201) {
      showEnterCode.value = true;
      startCodeTimeout();
    }

    if (response.status === 200 && response.data.status !== 201) {
      localStorage.setItem("user", JSON.stringify(response.data));
      showEnterCode.value = false;
      if (timeoutId.value) {
        clearTimeout(timeoutId.value);
        timeoutId.value = null;
      }

      await store.dispatch('updateUser', response.data);

      await router.push("/dashboard");
    }
  } catch (error) {
    console.log("error", error);
  }
};

watch(showEnterCode, (newValue) => {
  if (!newValue && timeoutId.value) {
    clearTimeout(timeoutId.value);
    timeoutId.value = null;
  }
});

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  body.classList.add("bg-gray-100");
});

if (timeoutId.value) {
    clearTimeout(timeoutId.value);
  }
</script>
<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card card-plain" v-if ="!showEnterCode">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Sign In</h4>
                  <p class="mb-0">Enter your phone number and password to sign in</p>
                </div>
                <div class="card-body">
                  <form role="form">
                    <div class="mb-3">
                      <argon-input
                        id="phone number"
                        type="phone number"
                        placeholder="Phone Number"
                        name="phone number"
                        size="lg"
                        v-model="user.msisdn"
                      />
                    </div>
                    <div class="mb-3">
                      <argon-input
                        id="password"
                        type="password"
                        placeholder="Password"
                        name="password"
                        v-model="user.password"
                        size="lg"
                      />
                    </div>
                    <argon-switch id="rememberMe" name="remember-me"
                      >Remember me</argon-switch
                    >
                    <div class="text-center">
                      <p @click="signIn" class="btn mb-0 bg-gradient-success btn-lg w-100 null mt-4">
                        Sign in
                      </p>
                    </div>
                  </form>
                </div>
              </div>
              <div class="card card-plain" v-if= "showEnterCode">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Sign In</h4>
                  <p class="mb-0">Enter verification code sent to your phone number</p>
                </div>
                <div class="card-body">
                  <form role="form">
                    <div class="mb-3">
                      <argon-input
                        id="verification code"
                        type="verification code"
                        placeholder="Verification code"
                        name="verification code"
                        size="lg"
                        v-model="user.code"
                      />
                    </div>            
        
                    <div class="text-center">
                      <p @click="signIn" class="btn mb-0 bg-gradient-success btn-lg w-100 null mt-4">
                        Sign in
                      </p>
                    </div>
                  </form>
                </div>
              </div>
              
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="
                  background-image: url(&quot;https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg&quot;);
                  background-size: cover;
                "
              >
                <span class="mask bg-gradient-success opacity-6"></span>
                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >
                  "Attention is the new currency"
                </h4>
                <p class="text-white position-relative">
                  In the business world ,the rearview mirror is always clearer than the windshield

                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
