<script setup>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref, watch, onMounted, computed } from "vue";
import axios from "axios";
import { useStore } from "vuex";
import ArgonButton from "@/components/ArgonButton.vue";

const store = useStore();
/*status mapping*/
const statusMapping = {
  0: "Lost",
  1: "Won",
  3: "Voided",
  4: "Cashout",
};

const getStatusName = (status) => {
  return statusMapping[status] || "Unknown";
};

/*datepicker*/
const formatDate = (date) => {
  return date.getFullYear() + "-" +
      String(date.getMonth() + 1).padStart(2, "0") + "-" +
      String(date.getDate()).padStart(2, "0");
};

const today = new Date();
const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

const startDate = ref(firstDayOfMonth);
const endDate = ref(today);
const pickerDate = ref([firstDayOfMonth, today]);

/*Filters*/
const outcomeSummary = ref([]);
const groupBy = ref("daily");
const statusFilter = ref(null);

const currentPage = ref(1);
const perPage = ref(20);
const totalPages = computed(() => Math.ceil(outcomeSummary.value.length / perPage.value));

const formatPeriod = (timestamp) => timestamp.split("T")[0];

/*getters*/
const fetchOutcomeSummary = async () => {
  const baseurl = process.env.VUE_APP_API_TAX_URL;
  const user = store.state.user;

  const start = formatDate(startDate.value);
  const end = formatDate(endDate.value);
  const group = groupBy.value;
  const status = statusFilter.value;

  let url = `${baseurl}/stake/outcome?start=${start}&end=${end}&group_by=${group}`;

  if (status !== null) {
    url += `&status=${status}`;
  }

  try {
    const response = await axios.post(
      url,
      {},
      {
        headers: {
          "api-key": user.auth,
        },
      }
    );

    if (response.data && response.data.outcome_summary) {
      outcomeSummary.value = response.data.outcome_summary
        .filter(item => statusFilter.value !== null ? item.status === statusFilter.value : item.status === 1)
        .sort((a, b) => new Date(b.period) - new Date(a.period));
      currentPage.value = 1;
    } else {
      outcomeSummary.value = [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

/*pagination*/
const paginatedData = computed(() => {
  const start = (currentPage.value - 1) * perPage.value;
  const end = start + perPage.value;
  return outcomeSummary.value.slice(start, end);
});

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

onMounted(fetchOutcomeSummary);

watch(pickerDate, (newDateRange) => {
  if (newDateRange && newDateRange.length === 2) {
    startDate.value = newDateRange[0];
    endDate.value = newDateRange[1];
    fetchOutcomeSummary();
  }
});

watch(groupBy, fetchOutcomeSummary);
watch(statusFilter, fetchOutcomeSummary);
</script>


<template>
  <div class="container py-4">
    <div class="card mt-4">
      <div class="card-header pb-0 p-3 d-flex justify-content-between align-items-center">
        <h6 class="mb-0">Withholding Reports</h6>

        <div class="d-flex gap-2">
          <!-- Date Picker -->
          <div>
            <VueDatePicker
                v-model="pickerDate"
                range
                multi-calendars
                :enable-time-picker="false"
                :clearable="false"
                :input-class="'custom-datepicker'"
            />
          </div>

          <!-- Group By Dropdown -->
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown">
              <i class="fas fa-filter"></i> Group By
            </button>
            <ul class="dropdown-menu">
              <li @click="groupBy = 'daily'"><a class="dropdown-item" href="#">Daily</a></li>
              <li @click="groupBy = 'monthly'"><a class="dropdown-item" href="#">Monthly</a></li>
            </ul>
          </div>

          <!-- Status Filter Dropdown -->
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown">
              <i class="fas fa-filter"></i> Status
            </button>
            <ul class="dropdown-menu">
              <li @click="statusFilter = 0"><a class="dropdown-item" href="#">Lost</a></li>
              <li @click="statusFilter = 1"><a class="dropdown-item" href="#">Won</a></li>
              <li @click="statusFilter = 3"><a class="dropdown-item" href="#">Voided</a></li>
              <li @click="statusFilter = 4"><a class="dropdown-item" href="#">Cashout</a></li>
              <li @click="statusFilter = null"><a class="dropdown-item" href="#">All</a></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="card-body p-3">
        <table class="table">
          <thead>
          <tr>
            <th>Transaction ID</th>
            <th>Period</th>
            <th>Total Winning</th>
            <th>Tax on Winning</th>
            <th>Status</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in paginatedData" :key="index">
            <td>{{ item.id }}</td>
            <td>{{ formatPeriod(item.period) }}</td>
            <td>{{ item.total_winning }}</td>
            <td>{{ item.tax_on_winning }}</td>
            <td>{{ getStatusName(item.status) }}</td>

          </tr>
          </tbody>
        </table>
      </div>

      <!-- Pagination Controls -->
      <div class="row mt-3">
        <div class="col-12 text-center">
          <argon-button @click="prevPage" :disabled="currentPage === 1">
            Previous
          </argon-button>
          <span>Page {{ currentPage }} of {{ totalPages }}</span>
          <argon-button @click="nextPage" :disabled="currentPage === totalPages">
            Next
          </argon-button>
        </div>
      </div>

    </div>
  </div>
</template>

<style>
.custom-datepicker {
  background-color: #8392ab !important;
}
</style>











